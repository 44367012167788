import { MatRadioChange } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';
/* eslint-disable no-shadow */
import { NgRedux } from '@angular-redux/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { delay, repeat, tap } from 'rxjs/operators';
import { ApplicativeErrorDialogComponent, ConfirmationMessageDialogComponent, Meeting, MeetingService, Mongo, Tenant, TenantService } from 'oa-lib';
import { Speech, SpeechStatusType } from 'oa-lib';
import { IAppState } from 'oa-lib';
import * as moment from 'moment';
import { PolGroup, PolGroupService } from 'src/app/services/polgroup.service';
import {UtilsProService} from 'src/app/services/utils.service';
export enum SpeechDurationType {
  UNLIMITED = 1,
  LIMITED = 2
}

import AgendaItemSpeechLimit=Meeting.Agenda.AgendaItemSpeechLimit;
const DATA_UPDATE_INTERVAL = 5 * 1000;
@Component({
  selector: 'app-meeting-speech-management',
  templateUrl: './meeting-speech-management.component.html',
  styleUrls: ['./meeting-speech-management.component.scss'],
})
export class MeetingSpeechManagementComponent implements OnInit, OnDestroy {
  POLLING_ENABLED = true;

  debate = false;
  UNLIMITED_UNTIL = moment().add(999, 'hours').valueOf();
  userId: string;

  meeting_oid: string | null = null;
  SpeechStatusType = SpeechStatusType;

  statusPollSubscription: Subscription;

  speechDuration = '05:00';
  speechDurationType: SpeechDurationType = SpeechDurationType.LIMITED;

  meeting: Observable<Meeting | any>;
  meetingGlobalStatus: Observable<Meeting.MeetingGlobalStatus>;

  currentAgendaItem: Meeting.MeetingGlobalStatus.AgendaItem | null = null;

  synoptic_enabled = false;
  speeches: Observable<Speech[] | null>;
  actual_speeches: Speech[] = [];
  canUpdateSpeeches = true;
  loadingSpeeches = false;
  anyRunningSpeech = false;
  showSpeechesLimits_table = false;
  SpeechDurationType = SpeechDurationType;

  pol_groups_tenant: PolGroup[] = [];

  summary_table_speech_limits: any[] = [];
  speech_limits: any[] = [];

  stopPrenotazioni = false;
  tenant: Tenant | null=null;
  constructor(
    private dialog: MatDialog,
    private redux: NgRedux<IAppState>,
    private meetingSvc: MeetingService,
    private polgroupService: PolGroupService,
    private tenantService: TenantService,
    private utilsProService: UtilsProService

  ) {}

  async init() {
    try {
      const restenant = await this.polgroupService.getPolGroupsOfTenant().toPromise();
      this.pol_groups_tenant = restenant.body;
      const res = await this.tenantService.getTenant().toPromise();
      this.tenant = res.body;
      console.log('tenant: ', this.tenant);
      this.synoptic_enabled = this.tenant?.synoptic_enabled || false;
      console.log("meeting-speech-management: synoptic_enabled: ", this.synoptic_enabled);
    } catch (err) {
      console.error(err);
    }
  }
  ngOnInit(): void {
    this.init();
    const userId = this.redux.getState().auth.userid;
    if (userId) {
      this.userId = userId;
    } else {
      throw new Error('Cannot retrieve userId from state');
    }

    this.meetingGlobalStatus = this.meetingSvc.getMeetingStatus();

    this.meetingGlobalStatus.subscribe((statusResponse) => {
      if (statusResponse.meeting) {
        this.meetingSvc
          .retrieveMeeting(statusResponse.meeting._id.$oid)
          .subscribe((meeting: Meeting | null) => {
            this.meeting = of(meeting);
            this.meeting.subscribe((asd) => {
              const asd2 = asd as any;
              this.stopPrenotazioni = asd2?.stopPrenotazioni || false;
            });

          });
        this.refreshStatus();
      } else {
        this.meeting = of({});
      }
    });

    if (this.POLLING_ENABLED) {
      this.statusPollSubscription = of({})
        .pipe(
          tap(this.refreshStatus.bind(this)),
          delay(DATA_UPDATE_INTERVAL),
          repeat()
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.clearSubScriptions();
  }

  clearSubScriptions() {
    if (this.statusPollSubscription) {
      this.statusPollSubscription.unsubscribe();
    }
  }

  setAnyRunningSpeech(speeches: Speech[]) {
    for (const s of speeches) {
      if (this.isSpeechRunning(s)) {
        return (this.anyRunningSpeech = true);
      }
    }
    return (this.anyRunningSpeech = false);
  }

  async toggleSlider(event: any) {
    console.log('toggleSlider: ', event);
    if (!this.meeting_oid) {
      console.error('toggleSlider: meeting_oid is null');
      return;
    }
    if (event.checked) {
      console.log("going to stop prenotazioni");
      const res =  this.utilsProService.stopPrenotazioniParoleMeeting(this.meeting_oid, true).subscribe();
      console.log("stopPrenotazioniParoleMeeting res: ", res);
    }
    else {
      console.log("going to start prenotazioni");
      const res = this.utilsProService.stopPrenotazioniParoleMeeting(this.meeting_oid, false).subscribe();
      console.log("stopPrenotazioniParoleMeeting res: ", res);
    }
  }
  isSpeechRunning(speech: Speech) {
    return (
      speech.status === SpeechStatusType.ENABLED && this.untilInFuture(speech)
    );
  }

  untilInFuture(speech: Speech) {
    return speech.until?.$date
      ? moment.now() < moment(speech.until?.$date).valueOf()
      : false;
  }

  async refreshStatus() {
    const status = await this.meetingGlobalStatus.toPromise();

    this.meeting_oid = status?.meeting?._id?.$oid || null;
    //console.log("Meeting oid: ", this.meeting_oid);
    if (!status.debate) {
      this.debate = status.debate;
      return this.clearSubScriptions();
    }

    if (status?.meeting?._id?.$oid && status.debate) {
      this.debate = status.debate;
      this.loadingSpeeches = true;
      this.currentAgendaItem = status.agendaItem;
      //console.log('currentAgendaItem: ', this.currentAgendaItem);
      this.speech_limits = this.currentAgendaItem?.speech_limits || [];
      this.meetingSvc
        .getMeetingAgendaItemSpeeches(
          status.meeting._id.$oid,
          status.agendaItem.item_number
        )
        .subscribe((res) => {
          this.loadingSpeeches = false;
          if (this.debate) {
            this.setAnyRunningSpeech(res.body as Speech[]);
            this.speeches = of(res.body as Speech[]);
            this.actual_speeches = res.body as Speech[];
            this.refreshSpeeches_limits();
          }
        });
    }
  }

  refreshSpeeches_limits() {
    if (this.speech_limits == null || this.speech_limits.length == 0
    || this.pol_groups_tenant == null || this.pol_groups_tenant.length == 0) {
      //nothing to show
      this.showSpeechesLimits_table = false;
      this.summary_table_speech_limits = [];
      return;
    }
    const filtered_group_to_show = this.pol_groups_tenant.filter((pol_group: PolGroup) => { return !pol_group.hidden;});
    this.summary_table_speech_limits = filtered_group_to_show.map((pol_group: PolGroup) => {

      if (pol_group.hidden) {
        return;
      }
      const hard_limit_sec = this.get_hard_limit_in_seconds_for_group(pol_group._id);
      let hard_limit_label = ' -- ';
      if (hard_limit_sec > 0) {
        hard_limit_label = moment.utc(hard_limit_sec * 1000).format('HH:mm:ss');
      }
      const cumulative_for_group_sec = this.get_total_seconds_for_group(pol_group._id);
      const cumulative_for_group_label = moment.utc(cumulative_for_group_sec * 1000).format('HH:mm:ss');
      const obj_summary = {
        pol_group_name: pol_group.name,
        pol_group_id: pol_group._id,
        hard_limit: hard_limit_label,
        cumulative: cumulative_for_group_label,
        overflow: hard_limit_sec > 0 && cumulative_for_group_sec > hard_limit_sec
      };
      return obj_summary;
     });


    this.showSpeechesLimits_table = true;
  }
  get_total_seconds_for_group(pol_group_id: string): number {
    let total_seconds = 0;
    for (const speech of this.actual_speeches) {
      if (speech.status == SpeechStatusType.BOOKED) {
        continue;
      }
      if (speech.pol_group == pol_group_id) {
        total_seconds += this.calculate_total_seconds_for_speech(speech);
      }
    }
    return total_seconds;
  }

  calculate_total_seconds_for_speech(speech: Speech): number {

    if (speech.status != SpeechStatusType.ENABLED) {
      return 0;
    }
    const startedAt = speech.startedAt?.$date;
    if (!startedAt) {
      console.error('calculate_total_seconds_for_speech: startedAt is null for speech:',speech);
      return 0;
    }
    const startedAt_date = moment(speech.startedAt?.$date).valueOf();
    let until = moment.now();
    if (this.isSpeechCompleted(speech)) {
      until = moment(speech.until?.$date).valueOf();
    }
    const total_seconds = Math.floor((until - startedAt_date) / 1000);
    return total_seconds;



  }
  untilInPast(speech: Speech) {
    return speech.until?.$date ?
      moment.now() > moment(speech.until?.$date).valueOf()
      : false;
  }
  isSpeechCompleted(speech: Speech) {
    return speech.status === SpeechStatusType.ENABLED && this.untilInPast(speech);
  }
  get_hard_limit_in_seconds_for_group(pol_group_id: string): number {
    const speech_limit: AgendaItemSpeechLimit = this.speech_limits.find((sp_l: AgendaItemSpeechLimit) => {
      return sp_l.pol_group_id==pol_group_id;
    });
    if (speech_limit) {
      return speech_limit.limit_seconds;
    }
    return 0;
  }
  speechDurationRadioChange(event: MatRadioChange) {
    this.speechDurationType = event.value as SpeechDurationType;
  }

  trackSpeechItems(index: number, item: Speech) {
    //console.log('tracking: ', item);
    return item._etag?.$oid;
  }
 async  enableSpeechFromSynoptic(event: any) {
    console.log('enableSpeechFromSynoptic: ', event); //enableSpeechFromSynoptic:  649b063f6db2ba3e40492abd

    const speech_oid = event;
    const speech = this.actual_speeches.find((sp: Speech) => {
      return sp._id?.$oid == speech_oid;
    });
    if (!speech) {
      console.error('enableSpeechFromSynoptic: speech not found for oid:', speech_oid);
      throw new Error('enableSpeechFromSynoptic: speech not found for oid');
      return;
    }
    const unlimited = this.isUnlimitedDuration(speech);
    const until = unlimited
    ? this.UNLIMITED_UNTIL
      : this.getUntil();
    if (!until) {
      console.error('enableSpeechFromSynoptic: until is null');
      throw new Error('enableSpeechFromSynoptic: until is null');
      return;
    }
    const res = await this.meetingSvc.enableSpeech(speech_oid, new Mongo.Date(until), unlimited).toPromise();
   console.log('enableSpeechFromSynoptic: res:', res);
   if (res && res.status == 200) {
    this.refreshStatus();
     //this.showOkMsg('Intervento abilitato con successo.');

     return;
   }
   this.showErrorMsg('Errore durante l\'abilitazione dell\'intervento.');
   console.error('enableSpeechFromSynoptic: error:', res);
  }
 async  closeSpeechFromSynoptic(event: any) {
    console.log('closeSpeechFromSynoptic: ', event);
    const speech_oid = event;
    const speech = this.actual_speeches.find((sp: Speech) => {
      return sp._id?.$oid == speech_oid;
    });
    if (!speech) {
      console.error('enableSpeechFromSynoptic: speech not found for oid:', speech_oid);
      throw new Error('enableSpeechFromSynoptic: speech not found for oid');
      return;
    }
   const res = await this.meetingSvc.stopSpeech(speech_oid).toPromise();
   console.log('closeSpeechFromSynoptic: res:', res);
   if (res && res.status == 200) {
    this.refreshStatus();
     //this.showOkMsg('Intervento chiuso con successo.');
     return;
   }
   this.showErrorMsg('Errore durante la chiusura dell\'intervento.');
    console.error('closeSpeechFromSynoptic: error:', res);

  }
  isUnlimitedDuration(speech: Speech) {
    return speech.unlimited === true ||
      this.speechDurationType === SpeechDurationType.UNLIMITED;
  }
  getUntil() {
    if (this.speechDuration) {
      const splitted = this.speechDuration.split(':');
      if (splitted?.length === 2) {
        return moment()
          .add(splitted[0], 'minutes')
          .add(splitted[1], 'seconds')
          .valueOf();
      }
      console.error('getUntil: invalid speechDuration:', this.speechDuration);
      return null;
    }
    return null;
  }
  showOkMsg(msg: string) {
    this.dialog.open(ConfirmationMessageDialogComponent, {
      data: JSON.stringify({
        message: msg,
        redirectTo: null,
      })
    });
  }
  showErrorMsg(msg: string) {

    this.dialog.open(ApplicativeErrorDialogComponent, {
      data: JSON.stringify({
        message:
          msg,
        redirectTo: null,
      }),
    });
  }
}

