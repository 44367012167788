<div *ngIf="meeting | async  as _meeting; else loading">
  <div *ngIf="currentAgendaItem && debate; else noDebate" class="mt-1 speech-controls">

    <synoptic *ngIf="synoptic_enabled"
    [tenant]="this.tenant" [speeches]="actual_speeches"
    [meeting_oid]="this.meeting_oid"
    (enable_speech)="enableSpeechFromSynoptic($event)"
    (close_speech)="closeSpeechFromSynoptic($event)"
    (forceRefreshSpeeches)="refreshStatus()">
  </synoptic>
  <mat-expansion-panel *ngIf="showSpeechesLimits_table" class="mb-4">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 class="w-100 text-center">Contingentamento ODG</h4>
      </mat-panel-title>
      <mat-panel-description>
        Clicca per visualizzare il contingentamento ODG
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="d-flex align-items-center justify-content-between w-100 mb-2">
      <div class="gl-group-label">
        <strong>Nome gruppo politico</strong>
      </div>
      <div class="gl-group-cumulative">
        <strong>Tempo cumulativo intervento</strong>
      </div>
      <div class="gl-group-limit">
        <strong>Tempo limite</strong>
    </div>
      </div>
      <div *ngFor="let grlim of summary_table_speech_limits" class="d-flex align-items-center justify-content-between w-100 mb-2" [ngClass]="{'overflow':grlim.overflow}">
        <div class="gl-group-label">
          {{grlim.pol_group_name}}
        </div>
        <div class="gl-group-cumulative">
          {{grlim.cumulative}}
        </div>
        <div class="gl-group-limit">
          {{grlim.hard_limit}}
      </div>
    </div>
  </mat-expansion-panel>
    <!-- <div class="row mb-4" *ngIf="showSpeechesLimits_table">
      <div class="col-12 mx-auto">
        <h4 class="w-100 text-center">Contingentamento ODG</h4>
        <div class="d-flex align-items-center justify-content-between w-100 mb-2">
          <div class="gl-group-label">
            <strong>Nome gruppo politico</strong>
          </div>
          <div class="gl-group-cumulative">
            <strong>Tempo cumulativo intervento</strong>
          </div>
          <div class="gl-group-limit">
            <strong>Tempo limite</strong>
        </div>
          </div>
        <div *ngFor="let grlim of summary_table_speech_limits" class="d-flex align-items-center justify-content-between w-100 mb-2" [ngClass]="{'overflow':grlim.overflow}">
          <div class="gl-group-label">
            {{grlim.pol_group_name}}
          </div>
          <div class="gl-group-cumulative">
            {{grlim.cumulative}}
          </div>
          <div class="gl-group-limit">
            {{grlim.hard_limit}}
        </div>
      </div>
    </div>
    </div> -->



    <div class="row">
      <div class="col-12 mx-auto">
        <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">

          <label class="pl-3 pl-md-0" style="min-width: 125px !important">Tempo di parola:</label>

          <mat-radio-group (change)="speechDurationRadioChange($event)" aria-label="Votazione segreta">
            <mat-radio-button class="ml-3" [value]="SpeechDurationType.UNLIMITED">Illimitato</mat-radio-button>
            <mat-radio-button checked class="ml-3" [value]="SpeechDurationType.LIMITED">Countdown</mat-radio-button>
          </mat-radio-group>

          <mat-form-field class="ml-3" appearance="outline">
            <mat-label>Durata interventi</mat-label>
            <input matInput [disabled]="speechDurationType!==SpeechDurationType.LIMITED" type="text" name="duration"
              [dropSpecialCharacters]="false" [clearIfNotMatch]="true" [showMaskTyped]="true" suffix=" minuti"
              mask="00:00" value="05:00" [(ngModel)]="speechDuration" />
          </mat-form-field>

          <mat-slide-toggle [(ngModel)]="stopPrenotazioni" (change)="toggleSlider($event)">
            Sospendi prenotazione interventi
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div *ngIf="speeches | async as _speeches" class="col-12">
        <div *ngIf="_speeches.length; else noSpeeches" id="management-speech-list">
          <ng-container *ngFor="let speech of _speeches;trackBy:trackSpeechItems">

            <div *ngIf="!isSpeechCompleted(speech)" class="d-inline-flex align-items-center justify-content-between w-100 mb-2">
              <app-meeting-speech-item *ngIf="!isSpeechCompleted(speech)"  class="w-100" [isManager]="true" [userId]="userId" [meeting]="_meeting"
                [speech]="speech" [loadingSpeeches]="loadingSpeeches" [anyRunningSpeech]="anyRunningSpeech"
                [speechDuration]="speechDuration" [speechDurationType]="speechDurationType"
                (updateSpeechesEvent)="refreshStatus()">
              </app-meeting-speech-item>
            </div>

          </ng-container>

        </div>
        <ng-template #noSpeeches>
          <h3 class="text-center">Ancora nessun intervento.</h3>
        </ng-template>
      </div>
    </div>
  </div>
</div>


<ng-template #loading>
  <div class="d-flex w-100 p-5 align-content-center justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #noDebate>
  <h3 class="text-center">Nessuna discussione in corso.</h3>
</ng-template>