import { MeetingManagementComponent } from './components/meeting/meeting-management/meeting-management.component';
import { GoogleAuthSetupComponent } from './components/user-profile/google-auth/google-auth-setup.component';
import { MeetingEditorComponent } from './components/meeting-editor/meeting-editor.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ElectionBallotComponent } from './components/ballot/election-ballot/election-ballot.component';
import { MeetingCalendarComponent } from './components/meeting/meeting-calendar/meeting-calendar.component';
import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentComponent } from './components/document/document/document.component';
import { MeetingCallListComponent } from './components/meeting/meeting-management/components/meeting-call-list/meeting-call-list.component';
import {
  MeetingCurrentComponent,
  AppErrorPageComponent,
  RehydratedGuard,
  AuthGuard,
  AccountVerificationGuard,
  OtpConfigurationGuard,
  NotAuthGuard,
  MeetingDetailComponent,
  MeetingAgendaComponent,
  MeetingCurrentBallotComponent,
  MeetingCallComponent,
  MeetingSpeechComponent,
  BallotResultComponent,
  BallotReceiptComponent,
  SigninVerifyComponent,
  SigninComponent,
  SigninActivateComponent,
  SigninResetPasswordComponent
} from 'oa-lib';
import {DcernoLoginComponent} from './components/dcerno-login.component';
import { CardManager } from './components/card-manager/card-manager.component';
import { SynopticManagerComponent } from './components/synoptic-manager/synoptic-manager.component';
import { SynopticViewerComponent } from './synoptic-viewer/synoptic-viewer.component';
import { MeetingAttendanceComponent } from './components/meeting/attendance/meeting-attendance.component';
import { MeetingAttendanceDelegateComponent } from './components/meeting/attendance/attendance-delegate/meeting-attendance-delegate.component';
import {CongediEditorComponent} from './components/meeting-editor/congedi-editor/congedi-editor.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [RehydratedGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [RehydratedGuard, AuthGuard],
        children: [
          {
            path: '',
            canActivate: [AccountVerificationGuard],
            children: [
              {
                path: '',
                canActivate: [OtpConfigurationGuard],
                children: [
                  {
                    path: '',
                    component: MeetingCalendarComponent,
                  },
                  {
                    path: 'profile',
                    component: UserProfileComponent,
                  },
                  {
                    path: 'meeting',
                    component: MeetingDetailComponent,
                  },
                  {
                    path: 'meeting/current',
                    component: MeetingCurrentComponent,
                  },
                  {
                    path: 'meeting/current/ballot',
                    component: MeetingCurrentBallotComponent,
                  },
                  {
                    path: 'meeting/ballot',
                    component: ElectionBallotComponent,
                  },
                  {
                    path: 'meeting/management',
                    component: MeetingManagementComponent,
                  },
                  {
                    path: 'meeting/agenda',
                    component: MeetingAgendaComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/ballot/result/:id',
                    component: BallotResultComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/attendance',
                    component: MeetingAttendanceComponent,
                  },
                  {
                    path: 'meeting/attendance/delegate/:inputBadgeData',
                    component:MeetingAttendanceDelegateComponent
                  },
                  {
                    path: 'meeting/receipt/:id',
                    component: BallotReceiptComponent,
                  },
                  {
                    path: 'meeting/editor/:id',
                    component: MeetingEditorComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/call',
                    component: MeetingCallComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/speeches',
                    component: MeetingSpeechComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/calls',
                    component: MeetingCallListComponent,
                  },
                  {
                    path: 'meeting/:idMeeting',
                    component: MeetingDetailComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/documents',
                    component: DocumentComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/congedi',
                    component: CongediEditorComponent,
                  },
                  {
                    path: 'meeting/:idMeeting/agenda/:idAgenda',
                    component: MeetingAgendaComponent,
                  }
                ],
              },
            ],
          },
          {
            path: 'card-manager',
            component: CardManager
          },
          {
            path: 'synoptic-viewer',
            component: SynopticViewerComponent
          },
          {
            path: 'synoptic-manager',
            component:SynopticManagerComponent
          },
          {
            path: 'signin/verify',
            component: SigninVerifyComponent,
          },
          {
            path: 'profile/google-auth',
            component: GoogleAuthSetupComponent,
          },
        ],
      },
      {
        path: '',
        canActivate: [RehydratedGuard, NotAuthGuard],
        children: [
          {
            path: 'signin',
            component: SigninComponent,
          },
          {
            path: 'signin/activate',
            component: SigninActivateComponent,
          },
          {
            path: 'signin/reset',
            component: SigninResetPasswordComponent,
          },
          {
            path: 'dcerno-login',
            component: DcernoLoginComponent,
          }

        ],
      },
      {
        path: 'error',
        component: AppErrorPageComponent,
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
