import { environment } from './../environments/environment';

/* eslint-disable max-len */
import { OpenAgendaItemDialogComponent } from './components/meeting/meeting-management/components/open-agenda-item-dialog/open-agenda-item-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MenuComponent } from './layout/components/menu/menu.component';
import { MobileMenuComponent } from './layout/components/menu/mobile-menu/mobile-menu.component';
import { FooterComponent } from './layout/components/footer/footer.component';
import { DesktopMenuComponent } from './layout/components/menu/desktop-menu/desktop-menu.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { BallotSummaryComponent } from './components/ballot/components/ballot-summary/ballot-summary.component';
/* eslint-disable max-len */
import { NgModule, ErrorHandler, Provider, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MeetingCalendarComponent } from './components/meeting/meeting-calendar/meeting-calendar.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { NavigationComponent } from './navigation/navigation.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { NgReduxModule } from '@angular-redux/store';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MeetingComponent } from './components/meeting/meeting/meeting.component';
import { LayoutComponent } from './layout/layout.component';
import { ElectionBallotComponent } from './components/ballot/election-ballot/election-ballot.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GoogleChartsModule } from 'angular-google-charts';
import { BallotSelectionDetailComponent } from './components/ballot/components/ballot-selection-detail/ballot-selection-detail.component';

import { MeetingEditorComponent } from './components/meeting-editor/meeting-editor.component';
import { AgendaItemEditorDialogComponent } from './components/meeting-editor/agenda-item-editor-dialog/agenda-item-editor-dialog.component';
import { AgendaItemComponent } from './components/meeting-editor/agenda-item/agenda-item.component';
import { GoogleAuthSetupComponent } from './components/user-profile/google-auth/google-auth-setup.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {
  DeleteDocumentModal,
  DocumentComponent,
} from './components/document/document/document.component';
import { MeetingManagementComponent } from './components/meeting/meeting-management/meeting-management.component';
import { AgendaItemElectionByListCandidatesDialogComponent } from './components/meeting-editor/agenda-item-editor-dialog/agenda-item-election-by-list-candidates-dialog/agenda-item-election-by-list-candidates-dialog.component';
import { MeetingSpeechManagementComponent } from './components/meeting/meeting-management/components/meeting-speech-management/meeting-speech-management.component';
import { MeetingCallListComponent } from './components/meeting/meeting-management/components/meeting-call-list/meeting-call-list.component';
import { MeetingOpenCallDialogComponent } from './components/meeting/meeting-management/components/meeting-open-call-dialog/meeting-open-call-dialog.component';
import { MeetingRescheduleDialogComponent } from './components/meeting/meeting-management/components/meeting-reschedule-dialog/meeting-reschedule-dialog.component';
import { OpenMeetingDialogComponent } from './components/meeting/meeting-management/components/open-meeting-dialog/open-meeting-dialog.component';

import { EllipsisModule } from 'ngx-ellipsis';

import {DcernoLoginComponent} from './components/dcerno-login.component';

/** Locale stuff */
import { LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';

import {
  OaLibModule,
  GlobalErrorHandler
} from 'oa-lib';
import { SynopticComponent } from './components/meeting/meeting-management/components/synoptic/synoptic.component';
import {CongediEditorComponent} from './components/meeting-editor/congedi-editor/congedi-editor.component';
import { CardManager } from './components/card-manager/card-manager.component';
import { SynopticManagerComponent } from './components/synoptic-manager/synoptic-manager.component';

import {MatExpansionModule} from '@angular/material/expansion';
import { SynopticViewComponent } from './components/meeting/meeting-management/components/synoptic-view/synoptic-view.component';
import { SynopticViewerComponent } from './synoptic-viewer/synoptic-viewer.component';
import { MeetingAttendanceComponent } from './components/meeting/attendance/meeting-attendance.component';
import {MeetingAttendanceDelegateComponent} from './components/meeting/attendance/attendance-delegate/meeting-attendance-delegate.component';
registerLocaleData(localeIT);
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


interface ModuleWithProviders<T = any> {
  ngModule: Type<T>;
  providers?: Provider[];
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const oaLibModule = OaLibModule.forRoot(environment);
@NgModule({
  declarations: [

    AppComponent,
    MeetingCalendarComponent,
    NavigationComponent,
    MeetingComponent,
    LayoutComponent,
    ElectionBallotComponent,
    BallotSummaryComponent,
    BallotSelectionDetailComponent,
    UserProfileComponent,
    MeetingEditorComponent,
    AgendaItemEditorDialogComponent,
    AgendaItemComponent,
    MobileMenuComponent,
    DesktopMenuComponent,
    FooterComponent,
    MenuComponent,
    GoogleAuthSetupComponent,
    DocumentComponent,
    MeetingManagementComponent,
    SynopticComponent,
    CongediEditorComponent,
    MeetingSpeechManagementComponent,
    AgendaItemElectionByListCandidatesDialogComponent,
    MeetingCallListComponent,
    MeetingOpenCallDialogComponent,
    DeleteDocumentModal,
    MeetingRescheduleDialogComponent,
    OpenMeetingDialogComponent,
    OpenAgendaItemDialogComponent,
    DcernoLoginComponent,
    CardManager,
    SynopticManagerComponent,
    SynopticViewComponent,
    SynopticViewerComponent,
    MeetingAttendanceComponent,
    MeetingAttendanceDelegateComponent
  ],
  imports: [
    BrowserModule,
    CommonModule ,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatListModule,
    MatRadioModule,
    MatBadgeModule,
    MatSelectModule,
    MatCheckboxModule,
    NgReduxModule,
    ReactiveFormsModule,
    GoogleChartsModule,
    ClipboardModule,
    oaLibModule,
    EllipsisModule,
    NgxMaskModule.forRoot(maskConfig),
    MatExpansionModule
  ],
  entryComponents: [
    AgendaItemEditorDialogComponent,
    AgendaItemElectionByListCandidatesDialogComponent,
    OpenMeetingDialogComponent,
    OpenAgendaItemDialogComponent,
    MeetingOpenCallDialogComponent,
    MeetingRescheduleDialogComponent,
    DeleteDocumentModal
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
