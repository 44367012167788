import {
  MatSnackBarDirective,
  MeetingService,
  UserRoles,
  Mongo,
  User,
  Meeting,
  UsersService,
  ConfirmActionDialogComponent,
  UserConfirmActionType,
  ConfirmationMessageDialogComponent,
} from 'oa-lib';
import Agenda = Meeting.Agenda;

/* eslint-disable no-underscore-dangle */
import { take, map, first } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
/* eslint-disable @typescript-eslint/prefer-for-of */
import { AgendaItemEditorDialogComponent } from './agenda-item-editor-dialog/agenda-item-editor-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
  FormControl,
} from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';

import Choice = Meeting.Agenda.Ballot.ElectionSpecification.Choice;
import Item = Meeting.Agenda.Item;
import Attendee = User.Attendee;
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as moment from 'moment';

const itemsCompareFn = (a: Item, b: Item) => {
  const aPath = a.item_number.split('.');
  const bPath = b.item_number.split('.');
  const aLength = aPath.length;
  const bLength = bPath.length;

  for (let i = 0; i < aLength && i < bLength; i++) {
    if (Number(aPath[i]) < Number(bPath[i])) {
      return -1;
    } else if (Number(aPath[i]) > Number(bPath[i])) {
      return 1;
    }
  }

  if (a.item_number < b.item_number) {
    return -1;
  } else {
    return 1;
  }
};

const notificationCompareFn = (
  a: Meeting.Notification,
  b: Meeting.Notification
) => {
  if (a.dispatchDate.$date < b.dispatchDate.$date) {
    return -1;
  } else {
    return 1;
  }
};
@Component({
  selector: 'app-meeting-editor',
  templateUrl: './meeting-editor.component.html',
  styleUrls: ['./meeting-editor.component.scss'],
})
export class MeetingEditorComponent
  extends MatSnackBarDirective
  implements OnInit, OnDestroy
{
  DATE_FORMAT = 'DD/MM/YYYY - HH:mm';

  init = false;
  creating: boolean;
  isSecondCall: boolean;
  processingSubmit = false;
  meeting: Observable<Meeting>;
  users: Observable<User[] | null>;
  meetingSub: Subscription;
  generalInfosFormGroup: FormGroup;
  items: Observable<Item[]>;
  attendees: Observable<Attendee[]> = of([]);
  notifications: Observable<
    { notification: Meeting.Notification; formControl: FormControl }[]
  > = of([]);
  groupsSelected: { name: string; users: Attendee[] }[] = [];
  groups: { name: string; users: Attendee[] }[];
  availableAttendees: Observable<Attendee[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    protected usersSvc: UsersService,
    protected meetingSvc: MeetingService,
    protected inj: Injector,
    private fb: FormBuilder
  ) {
    super(inj);
  }

  ngOnInit(): void {
    const meetingId = this.route.snapshot.params.id;

    if (meetingId !== 'new') {
      this.creating = false;
      this.meetingSvc
        .retrieveMeeting(meetingId)
        .subscribe((meeting: Meeting | null) => {
          if (meeting) {
            this.meeting = of(meeting);
            this.loadFormControls();
          } else {
            this.router.navigate(['']);
          }
        });
    } else {
      this.creating = true;
      this.meeting = of(new Meeting());
      this.loadFormControls();
    }
  }

  async getGroups() {
    const users = await this.usersSvc
      .getAvailableAttendees()
      .pipe(
        map((res) => res.body),
        take(1)
      )
      .toPromise();

    console.log('getAvailableAttendees: ', users);

    const groupNames: string[] = [];
    const groups: { name: string; users: Attendee[] }[] = [];

    if (users) {
      for (const user of users) {
        if (user.groups) {
          for (const group of user.groups) {
            if (!groupNames.includes(group)) {
              groupNames.push(group);
            }
          }
        }
      }

      for (const groupName of groupNames) {
        groups.push({ name: groupName, users: [] });
      }

      for (const user of users) {
        if (
          user.roles.includes(UserRoles.GUEST) ||
          user.roles.includes(UserRoles.VOTER)
        ) {
          for (const group of groups) {
            if (user.groups && user.groups.includes(group.name)) {
              let attendee = null;
              for (const _group of groups) {
                const filteredUser = _group.users.filter(
                  (u) => u._id === user._id
                );
                if (filteredUser.length > 0) {
                  attendee = filteredUser[0];
                  break;
                }
              }
              if (attendee) {
                group.users.push(attendee);
              } else {
                group.users.push({
                  _id: user._id,
                  name: user.name,
                  pec: user.pec,
                  guest: user.roles.includes(UserRoles.GUEST),
                  label: user.label,
                  countsForQuorum: user.countsForQuorum,
                  voteWeight: user.voteWeight,
                  email: user.email,
                });
              }
            }
          }
        }
      }

      this.groups = groups.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      });
    }

    const availableAttendees: Attendee[] = [];

    for (const group of groups) {
      for (const user of group.users) {
        if (!availableAttendees.includes(user)) {
          availableAttendees.push(user);
        }
      }
    }

    this.availableAttendees = of(
      availableAttendees.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      })
    );
  }

  async groupSelected(groupSelect: MatSelect) {
    const selectedGroups = groupSelect.value as {
      name: string;
      users: Attendee[];
    }[];

    await this.handleGroupSelected(selectedGroups);
  }

  async handleGroupSelected(
    selectedGroups: { name: string; users: Attendee[] }[]
  ) {
    const attendees: Attendee[] = [];
    for (const group of selectedGroups) {
      for (const user of group.users) {
        if (!attendees.includes(user)) {
          attendees.push(user);
        }
      }
    }

    this.attendees = of(attendees);
    // await this.updateSelectedGroups();
  }

  ngOnDestroy() {
    this.meetingSub.unsubscribe();
  }

  async loadFormControls() {
    this.meetingSub = this.meeting.subscribe(async (meeting) => {
      if (meeting.secondCall) {
        this.isSecondCall = true;
      }

      const startDateUnix = this.isSecondCall
        ? meeting.secondCall?.startDate
        : meeting.firstCall?.startDate;

      let startDate = '';

      if (startDateUnix) {
        startDate = this.getDateFromUnixEpoch(startDateUnix.$date);
      }

      this.generalInfosFormGroup = this.fb.group({
        title: [meeting.title, Validators.required],
        description: [meeting.description ? meeting.description : ''],
        startDate: [startDate, dateValidator()],
        notes: [meeting.notes ? meeting.notes : ''],
        link: [meeting.link ? meeting.link : ''],
      });

      if (meeting.status !== Meeting.Status.SCHEDULED) {
        this.generalInfosFormGroup.get('startDate')?.disable();
      }

      await this.getGroups();

      const selectedGroups: { name: string; users: Attendee[] }[] = [];
      const attendees: Attendee[] = [];

      for (const group of this.groups) {
        for (const user of group.users) {
          for (const meetingAttendee of meeting.attendees) {
            if (user._id === meetingAttendee._id && !attendees.includes(user)) {
              attendees.push(user);
            }
          }
        }
      }

      for (const group of this.groups) {
        let found = 0;

        for (const user of group.users) {
          if (attendees.includes(user)) {
            found++;
          }
        }

        if (found === group.users.length) {
          selectedGroups.push(group);
        }
      }

      await this.handleGroupSelected(selectedGroups);
      await this.updateSelectedGroups();
      this.items = of(meeting.agenda);
      this.attendees = of(attendees);

      const notifications: {
        notification: Meeting.Notification;
        formControl: FormControl;
      }[] = [];

      for (const notification of meeting.notifications) {
        const upcomingNotification = {
          notification,
          formControl: new FormControl(
            this.getDateFromUnixEpoch(notification.dispatchDate.$date),
            {
              validators: [dateValidator(), Validators.required],
              updateOn: 'blur',
            }
          ),
        };

        if (notification.processed) {
          upcomingNotification.formControl.disable();
        }

        notifications.push(upcomingNotification);
      }

      this.notifications = of(notifications);
      this.checkAddVote();

      this.init = true;
    });
  }

  getDateFromUnixEpoch(epoch: number) {
    return moment.unix(epoch / 1000).format(this.DATE_FORMAT);
  }

  getErrorMessage(formControl: AbstractControl | null) {
    if (!formControl) {
      return CustomValidationErrors.UNKNOWN_VALIDATION_ERROR;
    }
    const errors = formControl.errors;

    if (errors && errors.required) {
      return CustomValidationErrors.REQUIRED;
    }

    if (errors && errors.invalidDate) {
      return CustomValidationErrors.INVALID_DATE;
    }

    if (errors && errors.quorumExceeded) {
      return CustomValidationErrors.QUORUM_EXCEEDED;
    }

    return CustomValidationErrors.UNKNOWN_VALIDATION_ERROR;
  }

  checkAddVote(): void {
    console.log('checkAddVote');
    this.route.queryParams.subscribe((params) => {
      if (params.addVote) {
        this.addNewEntry();
        console.log('asd');
      }
    });
  }

  async addNewNotification() {
    const notifications = await this.notifications.toPromise();

    const newNotification = new Meeting.Notification();

    notifications.push({
      notification: newNotification,
      formControl: new FormControl(
        this.getDateFromUnixEpoch(newNotification.dispatchDate.$date),
        [dateValidator(), Validators.required]
      ),
    });

    // notifications.sort(notificationCompareFn);

    this.notifications = of(notifications);
  }

  async deleteNotification(notification: {
    notification: Meeting.Notification;
    formControl: FormControl;
  }) {
    const notifications = await this.notifications.toPromise();

    notifications.splice(
      notifications.findIndex((_n) => _n === notification),
      1
    );
  }

  public loadCSV() {
    /**[
    {
        "item_number": "1",
        "parent": null,
        "description": "",
        "title": "yiyiyik",
        "attachments": [],
        "ballot": {
            "specs": [
                {
                    "majority": {
                        "fraction": "1/2+1",
                        "of": "VOTERS"
                    },
                    "duration": 60
                }
            ],
            "periods": [],
            "id": {
                "$oid": "66549372e7344d5ff6f3aac7"
            },
            "type": 200
        },
        "status": "SCHEDULED",
        "speech_limits": []
    }
]
     *
     *
     */

    //fake it for now
    //number,parent,description,title,ballot.specs.majority.fraction,ballot.specs.majority.of,ballot.specs.duration,ballot.type
    // const csv_lines = [
    //   '1,,,prova ODG,1/2+1,VOTERS,120,200',
    //   '1.1,1,desc11,prova ODG2,2/3+1,VOTERS,180,201',
    //   '2,,desc2,prova ODG2,1/2+1,VOTERS,60,200',
    //   '2.1,2,desc21,prova ODG2,3/5+1,ATTENDEES,250,201',
    // ];
    // open dialog to pick the file
    console.log('loadCSV');
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';
    fileInput.click();
    fileInput.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result as string;
          const csv_lines = text.split(';');
          this.loadItemsViaCSV(csv_lines);
        };
        reader.readAsText(file);
      }
    };

  }
  loadItemsViaCSV(csv_lines: string[]) {
    //let assume we have already loaded somehow the csv lines
    console.log('loadItemsViaCSV: ', csv_lines);
    this.items = of([]);
    for (let i = 0; i < csv_lines.length; i++) {
      const line = csv_lines[i];
      const parts = line.split(',');
      const item_number = parts[0];
      const parent = parts[1];
      const description = parts[2];
      const title = parts[3];
      const ballot_specs_majority_fraction = parts[4];
      const ballot_specs_majority_of = parts[5];
      const ballot_specs_duration = parts[6];
      const ballot_type = parts[7];

      const new_item = new Item(item_number, parent, description, title);
      /**
       *
       * case this.BallotType.OPEN_VOTING_PROCEDURE: {
        this.ballot = new Agenda.OpenVotingBallot();
        this.enableMajorifyFromGroup();
        break;
      }
      case this.BallotType.SECRET_VOTING_PROCEDURE: {
        this.ballot = new Agenda.SecretVotingBallot();
        this.enableMajorifyFromGroup();
        break;
      }
      case this.BallotType.ELECTION_BY_COMPILATION: {
        this.ballot = new Agenda.ElectionByCompilationBallot();
        break;
      }
      case this.BallotType.ELECTION_BY_LIST: {
        this.ballot = new Agenda.ElectionByListBallot();
        this.enableChoiceFormGroup();
        break;
      }
       */
      if (ballot_type == '201' || ballot_type == '200') {
        const majority = new Agenda.Ballot.VoteSpecification.Majority();
        switch (ballot_specs_majority_fraction) {
          /**
           *  TWO_THIRDS = "2/3+1",
               HALF = "1/2+1",
               QUALIFIED_MAJORITY = "3/5+1"
           */
          case '1/2+1':
            majority.fraction = Agenda.Ballot.VoteSpecification.Majority.FRACTION.HALF;
            break;
          case '2/3+1':
            majority.fraction = Agenda.Ballot.VoteSpecification.Majority.FRACTION.TWO_THIRDS;
            break;
          case '3/5+1':
            majority.fraction = Agenda.Ballot.VoteSpecification.Majority.FRACTION.QUALIFIED_MAJORITY;
            break;
          default:
            console.error('Unknown majority fraction: ', ballot_specs_majority_fraction);
            break;
        }
        switch (ballot_specs_majority_of) {
          case 'VOTERS':
            majority.of = Agenda.Ballot.VoteSpecification.Majority.OF.VOTERS;
            break;
          case 'ATTENDEES':
            majority.of = Agenda.Ballot.VoteSpecification.Majority.OF.ATTENDEES;
            break;
          default:
            console.error('Unknown majority of: ', ballot_specs_majority_of);
            break;
        }

        const voteSpecification = new Agenda.Ballot.VoteSpecification(majority);
        voteSpecification.duration = Number(ballot_specs_duration);

        const votespecs = [voteSpecification];


        if (ballot_type == '201') {
          new_item.ballot = new Agenda.SecretVotingBallot(votespecs);
        } else if (ballot_type == '200') {
          new_item.ballot = new Agenda.OpenVotingBallot(votespecs);
        }
      } else {
        console.error('Unknown ballot type: ', ballot_type);
        continue;
      }

      this.items.pipe(take(1)).subscribe((items) => {
        this.items = of([...items, new_item].sort(itemsCompareFn));
      });
    }
  }

  addNewEntry() {
    this.items.subscribe((items) => {
      const dialogRef = this.dialog.open(AgendaItemEditorDialogComponent, {
        data: {
          selectedItem: new Item(this.getNewItemNumber(items)),
          items,
          creating: true,
        },
      });

      dialogRef.afterClosed().subscribe(async (newItem) => {
        if (newItem) {
          const parent = newItem.parent;

          this.items.pipe(take(1)).subscribe((it) => {
            if (parent) {
              const levelWidth = it.filter(
                (item) => item.parent === parent
              ).length;
              newItem.item_number = parent + '.' + String(levelWidth + 1);
            }

            this.items = of([...it, newItem].sort(itemsCompareFn));
          });
        }
      });
    });
  }

  editItem($event: Item) {
    this.items.subscribe((items) => {
      const dialogRef = this.dialog.open(AgendaItemEditorDialogComponent, {
        data: {
          selectedItem: $event,
          items,
        },
      });

      dialogRef.afterClosed().subscribe(async (editedItem: Item) => {
        if (editedItem) {
          const oldParent = $event.parent;
          if (oldParent !== editedItem.parent) {
            await this.changeItemRoot($event, editedItem.parent);
          }

          editedItem.parent = $event.parent;
          editedItem.item_number = $event.item_number;

          Object.assign($event, editedItem);

          this.items.pipe(take(1)).subscribe((it) => {
            this.items = of(it.sort(itemsCompareFn));
          });
        }
      });
    });
  }

  async deleteItem(target: Item) {
    console.log('[deleteItem]: ', target);
    const items = await this.items.toPromise();

    const targetIdx = items.findIndex((item) => item === target);
    const targetPath = target.item_number.split('.');

    for (let i = targetIdx + 1; i < items.length; i++) {
      const node = items[i];
      const nodePath = node.item_number.split('.');

      if (node.parent === target.parent) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        nodePath[nodePath.length - 1] = String(
          Number(nodePath[nodePath.length - 1]) - 1
        );
        node.item_number = await this.getItemNumberFromNodePath(nodePath);
      }

      if (this.isTargetChild(targetPath, nodePath)) {
        items.splice(
          items.findIndex((item) => item === node),
          1
        );
        i--;
      }
    }

    items.splice(targetIdx, 1);
  }

  isTargetChild(targetPath: string[], nodePath: string[]): boolean {
    if (!(targetPath.length < nodePath.length)) {
      return false;
    }

    for (let i = 0; i < targetPath.length; i++) {
      if (targetPath[i] !== nodePath[i]) {
        return false;
      }
    }

    return true;
  }

  getNewItemNumber(items: Item[]): string {
    return String(items.filter((item) => !item.parent).length + 1);
  }

  async attendeeChecked($event: MatCheckboxChange, selectedAttendee: Attendee) {
    const checked = $event.checked;
    const attendees = await this.attendees.toPromise();

    if (checked) {
      if (!attendees.includes(selectedAttendee)) {
        this.attendees = of([...attendees, selectedAttendee]);
      }
    } else {
      if (attendees.includes(selectedAttendee)) {
        this.attendees = of(attendees.filter((a) => a !== selectedAttendee));
      }
    }

    // await this.updateSelectedGroups();
  }

  async updateSelectedGroups() {
    const groups = this.groups;
    const attendees = await this.attendees.toPromise();
    const selectedGroups: { name: string; users: Attendee[] }[] = [];

    for (const group of groups) {
      let found = 0;

      for (const user of group.users) {
        if (attendees.includes(user)) {
          found++;
        }
      }

      if (found === group.users.length) {
        selectedGroups.push(group);
      }
    }

    this.groupsSelected = selectedGroups;
  }

  ////////////////////////////////////
  //  Item root change logic start  //
  ////////////////////////////////////

  async changeItemRoot(target: Item, newParentItemNumber: string | null) {
    const items = await this.items.toPromise();

    const newParent = items.filter(
      (item) => item.item_number === newParentItemNumber
    )[0];

    await this.moveNode(target, newParent);
  }

  async moveNode(
    target: Item,
    destination: Item,
    oldParent: Item | null = null
  ) {
    const items = await this.items.toPromise();
    const children = await this.getChildren(target);

    if (!oldParent) {
      const oldParentFilter = items.filter(
        (item) => item.item_number === target.parent
      );

      oldParent =
        oldParentFilter.length > 0
          ? Object.assign({}, oldParentFilter[0])
          : null;
    }

    const oldTarget = Object.assign({}, target);

    await this.updateNeighbours(oldParent, target.item_number);
    await this.changeParent(target, destination);

    for (const child of children) {
      await this.moveNode(child, target, oldTarget);
    }
  }

  async changeParent(node: Item, newParent: Item | null) {
    let newParentChidrenWidth = 0;

    const children = await this.getChildren(newParent);

    newParentChidrenWidth = children.length;

    node.parent = newParent ? newParent.item_number : null;

    node.item_number = node.parent ? node.parent : '';

    const nodePath = node.item_number.split('.');

    if (nodePath[0].length > 0) {
      nodePath[nodePath.length] = '';
    }

    nodePath[nodePath.length - 1] = String(newParentChidrenWidth + 1);

    node.item_number = await this.getItemNumberFromNodePath(nodePath);
  }

  async updateNeighbours(oldParent: Item | null, oldItemNumber: string) {
    const oldNodePath = oldItemNumber.split('.');
    const oldNodeIdx = Number(oldNodePath[oldNodePath.length - 1]);
    const neighbours = await this.getChildren(oldParent);

    let neighbourPath;
    let neighbourIdx;

    for (const neighbour of neighbours) {
      neighbourPath = neighbour.item_number.split('.');
      neighbourIdx = Number(neighbourPath[neighbourPath.length - 1]);

      if (neighbourIdx > oldNodeIdx) {
        const neighbourChilds = await this.getChildren(neighbour);
        neighbourPath[neighbourPath.length - 1] = String(neighbourIdx - 1);
        neighbour.item_number = await this.getItemNumberFromNodePath(
          neighbourPath
        );

        for (const child of neighbourChilds) {
          await this.updateParentChilds(child, neighbour.item_number);
        }
      }
    }
  }

  async updateParentChilds(targetChild: Item, newParent: string) {
    const oldParent = targetChild.parent;

    if (oldParent) {
      targetChild.item_number = targetChild.item_number.replace(
        oldParent + '.',
        newParent ? newParent + '.' : ''
      );
    } else {
      targetChild.item_number =
        (newParent ? newParent + '.' : '') + targetChild.item_number;
    }

    targetChild.parent = newParent;

    const children = await this.getChildren(targetChild);

    for (const child of children) {
      await this.updateParentChilds(child, targetChild.item_number);
    }
  }

  async getItemNumberFromNodePath(path: string[]) {
    let itemNumber = '';

    for (let j = 0; j < path.length; j++) {
      itemNumber += path[j] + (j < path.length - 1 ? '.' : '');
    }

    return itemNumber;
  }

  async getChildren(parent: Item | null): Promise<Item[]> {
    const items = await this.items.toPromise();

    return items.filter(
      (item) => item.parent === (parent ? parent.item_number : null)
    );
  }

  //////////////////////////////////
  //  Item root change logic end  //
  //////////////////////////////////

  async getNotification(): Promise<Meeting.Notification[]> {
    const notifications = await this.notifications.toPromise();

    for (const n of notifications) {
      if (n.formControl.dirty) {
        n.notification.dispatchDate.$date =
          moment(n.formControl.value, this.DATE_FORMAT).unix() * 1000;
      }
    }

    return notifications.map((n) => n.notification).sort(notificationCompareFn);
  }

  invalidNotifications(
    notifications: {
      notification: Meeting.Notification;
      formControl: FormControl;
    }[]
  ) {
    for (const notification of notifications) {
      if (notification.formControl.invalid) {
        return true;
      }
    }

    return false;
  }

  async submit() {
    const meeting = await this.meeting.toPromise();

    const generalInfos = this.generalInfosFormGroup.getRawValue();
    delete generalInfos.startDate;
    Object.assign(meeting, generalInfos);

    meeting[this.isSecondCall ? 'secondCall' : 'firstCall'] = this.getCall();
    meeting.attendees = await this.attendees.toPromise();
    meeting.agenda = await this.items.toPromise();
    meeting.notifications = await this.getNotification();

    console.log('[submit()]: ', meeting);

    const params: any = await this.route.queryParams.pipe(first()).toPromise();

    this.processingSubmit = true;
    if (this.creating) {
      this.meetingSvc.postMeeting(meeting).subscribe((res) => {
        this.processingSubmit = false;
        this.dialog.open(ConfirmationMessageDialogComponent, {
          data: JSON.stringify({
            message: 'Seduta creata con successo',
            redirectTo: params.returnTo ? params.returnTo : '',
          }),
        });
      });
    } else {
      this.meetingSvc.patchMeeting(meeting).subscribe((res) => {
        this.processingSubmit = false;
        this.dialog.open(ConfirmationMessageDialogComponent, {
          data: JSON.stringify({
            message: 'Seduta aggiornata con successo',
            redirectTo: params.returnTo ? params.returnTo : '',
          }),
        });
      });
    }
  }

  async deleteMeeting() {
    const meeting = await this.meeting.toPromise();
    if (meeting._id?.$oid) {
      this.dialog
        .open(ConfirmActionDialogComponent, {
          data: JSON.stringify({
            message: 'Vuoi davvero eliminare la seduta?',
          }),
        })
        .afterClosed()
        .subscribe((data: { action: UserConfirmActionType }) => {
          if (data?.action === UserConfirmActionType.CONFIRM) {
            this.meetingSvc.deleteMeeting(meeting).subscribe((res) => {
              if (res?.status === 204) {
                this.dialog.open(ConfirmationMessageDialogComponent, {
                  data: JSON.stringify({
                    message: 'Seduta eliminata correttamente',
                    redirectTo: '',
                  }),
                });
              } else {
                this.dialog.open(ConfirmationMessageDialogComponent, {
                  data: JSON.stringify({
                    message: 'Impossibile eliminare la seduta',
                    redirectTo: null,
                  }),
                });
              }
            });
          }
        });
    }
  }

  getCall() {
    const generalInfos = this.generalInfosFormGroup.getRawValue();

    const call = {
      startDate: new Mongo.Date(),
      endDate: null,
    };

    call.startDate.$date =
      moment(generalInfos.startDate, this.DATE_FORMAT).unix() * 1000;

    return call;
  }

  navigateBack(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.returnTo) {
        this.router.navigate(params.returnTo.split('/'));
      } else {
        this.router.navigate(['']);
      }
    });
  }
}

export const dateValidator =
  (): ValidatorFn =>
  (formControl: AbstractControl): { [key: string]: any } | null => {
    const DATE_FORMAT = 'DD/MM/YYYY - HH:mm';
    const errors: any = {};
    const dateString = formControl.value;
    const date = moment(dateString, DATE_FORMAT);

    if (!date.isValid()) {
      errors.invalidDate = true;
      return errors;
    }

    return null;
  };

export const quorumValidator =
  (component: MeetingEditorComponent): AsyncValidatorFn =>
  async (
    formControl: AbstractControl
  ): Promise<{ [key: string]: any } | null> => {
    const errors: any = {};
    const quorum = formControl.value;
    const _attendees = await component.attendees.toPromise();

    if (Number(quorum) > _attendees.length) {
      errors.quorumExceeded = true;
      return errors;
    }

    return null;
  };

// eslint-disable-next-line no-shadow
export enum CustomValidationErrors {
  UNKNOWN_VALIDATION_ERROR = 'Il valore nel campo inserito non è valido',
  REQUIRED = 'Il campo è obbligatorio',
  INVALID_DATE = 'La data inserita non è valida',
  QUORUM_EXCEEDED = 'Il quorum non può superare il numero di partecipanti',
}
