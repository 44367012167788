<div
  class="d-flex flex-column container justify-content-center mt-3 w-100"
  style="max-width: 1140px"
>
<div class="text-center">
    <h1><strong>Gestione congedi</strong></h1>


  </div>
  <div class="d-flex flex-row w-100 p-1 mt-3">
  <div class="w-50 align-content-center justify-content-center text-center">
    <ul class="w-100 list-panel-people">
      <li style="font-size: 22px;margin-bottom:10px;"><strong>Presenti</strong></li>
      <ng-container *ngIf="current_NON_congedati?.length>0 else no_non_congedati;">
        <li *ngFor="let it of current_NON_congedati">

            {{from_username_to_name(it)}}
            <button (click)="congeda(it)" class="ml-1 b-action-cong">></button>

        </li>

      </ng-container>
      <ng-template #no_non_congedati>
        <h4 class="text-center">Nessun presente.</h4>
      </ng-template>
    </ul>
  </div>
  <div class="w-50 align-content-center justify-content-center text-center">
    <ul class="w-100 list-panel-people">
      <li style="font-size: 22px;margin-bottom:10px;"><strong>Congedati</strong></li>
      <ng-container *ngIf="current_congedi?.length>0 else no_congedati;">
        <li *ngFor="let it of current_congedi">

            <button (click)="uncongeda(it)" class="mr-1 b-action-cong"><</button>

            {{from_username_to_name(it)}}


        </li>

      </ng-container>
      <ng-template #no_congedati>
        <h4 class="text-center">Nessun congedo presente.</h4>
      </ng-template>
    </ul>
  </div>
</div>

  <div class="d-flex justify-content-center w-100">
    <button
      (click)="navigateBack()"
      style="min-width: 115px"
      class="ml-1 mr-1"
      mat-raised-button
      color="primary"
    >
      ANNULLA
    </button>
    <button
      (click)="salva_congedi()"
      style="min-width: 115px"
      class="ml-1 mr-1"
      mat-raised-button
      color="primary"
    >
      <div class="d-flex align-items-center justify-content-center">SALVA</div>
    </button>
  </div>
</div>
