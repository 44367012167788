//per non toccare piu' la oa-lib

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Mongo, TENANT_NAME_PLACEHOLDER } from 'oa-lib';
import * as moment from 'moment';

const URL = `${environment.RH_BASE_URL}/${TENANT_NAME_PLACEHOLDER}`;

@Injectable({
    providedIn: 'root',
  })
  export class UtilsProService {

    constructor(private httpClient: HttpClient) {

    }
public stopPrenotazioniParoleMeeting(meetingOID: string,stopPrenotazioni: boolean): Observable<HttpResponse<any>> {
    const url = `${URL}/meetings/${meetingOID}`;

    return this.httpClient.patch(
      url,
      {
        stopPrenotazioni: stopPrenotazioni,
      },
      {
        withCredentials: true,
        observe: 'response',
        responseType: 'json',
      }
    );
}
public updateCongediUsernames(meetingOID: string,usernames_in_congedo: string[]): Observable<HttpResponse<any>> {
  const url = `${URL}/meetings/${meetingOID}`;

  return this.httpClient.patch(
    url,
    {
      current_congedi: usernames_in_congedo,
    },
    {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    }
  );
}

}
