import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  SynopticLayout,
  SynopticService,
} from 'src/app/services/synoptic.service';
import {
  ConfirmationMessageDialogComponent,
  ApplicativeErrorDialogComponent,
  MeetingService,
} from 'oa-lib';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsProService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-congedi-editor',
  templateUrl: './congedi-editor.component.html',
  styleUrls: ['./congedi-editor.component.scss'],
})
export class CongediEditorComponent implements OnInit {
  raw_meeting: any;
  meetingId: any;
  current_congedi: string[] = [];
  current_NON_congedati: string[] = [];

  map_username_name: any = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,

    protected meetingSvc: MeetingService,
    private utilsProService: UtilsProService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    console.log('congedi-editor ngOnInit');
    this.meetingId = this.route.snapshot.params.idMeeting;
    this.init();
  }

  async init() {
    if (!this.meetingId) {
      this.showErrorMsg('Meeting id not found');
      this.navigateBack();
    }
    this.meetingSvc.retrieveMeeting(this.meetingId).subscribe(
      (meeting: any) => {
        if (!meeting) {
          this.showErrorMsg('Meeting not found');
          this.navigateBack();
        }
        this.raw_meeting = meeting;
        console.log('congedi-editor Meeting loaded');
        console.log(this.raw_meeting);
        this.current_congedi = this.raw_meeting.current_congedi || [];
        this.recalculate_NON_congedati_and_sorts_arrays();
      },
      (error) => {
        this.showErrorMsg('Error loading meeting');
        this.navigateBack();
      }
    );
  }

  recalculate_NON_congedati_and_sorts_arrays() {
    this.current_NON_congedati = [];
    for (let i = 0; i < this.raw_meeting.attendees.length; i++) {
      this.map_username_name[this.raw_meeting.attendees[i]._id] =
        this.raw_meeting.attendees[i].name;
        const id_attendee = this.raw_meeting.attendees[i]._id;
        // console.log('attendee', this.raw_meeting.attendees[i]);
        // console.log('id_attendee', id_attendee);
      if (!this.current_congedi.includes(id_attendee)) {
        this.current_NON_congedati.push(id_attendee);
      }
    }
    //sort both arrays by name
    this.current_congedi.sort((a, b) =>
      this.map_username_name[a].localeCompare(this.map_username_name[b])
    );
    this.current_NON_congedati.sort((a, b) =>
      this.map_username_name[a].localeCompare(this.map_username_name[b])
    );
  }
  from_username_to_name(username: string) {
    //check if this.map_username_name[username] exists
    if (!this.map_username_name[username]) {
      return username;
    }
    return this.map_username_name[username];
  }
  async salva_congedi() {
    console.log('salva_congedi');
    const res = await this.utilsProService
      .updateCongediUsernames(this.meetingId, this.current_congedi)
      .toPromise();
      console.log(res);
      this.navigateBack();
  }

  congeda(username: string) {
    this.current_congedi.push(username);
    this.recalculate_NON_congedati_and_sorts_arrays();
  }
  uncongeda(username: string) {
      //remove username from current_congedi
      const index = this.current_congedi.indexOf(username);
        if (index > -1) {
            this.current_congedi.splice(index, 1);
        }

    this.recalculate_NON_congedati_and_sorts_arrays();
  }
  showErrorMsg(msg: string) {
    this.dialog.open(ApplicativeErrorDialogComponent, {
      data: JSON.stringify({
        message: msg,
        redirectTo: null,
      }),
    });
  }
  navigateBack(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.returnTo) {
        this.router.navigate(params.returnTo.split('/'));
      } else {
        this.router.navigate(['']);
      }
    });
  }
}
